<template>
  <div v-cloak>
    <CRow>
      <CCol :md="9" :sm="9" :xs="12">
        <h4 class="text-uppercase">Notifications</h4>
      </CCol>
    </CRow>

    <CRow>
      <CCol cols="12">
        <CCard>
          <CRow class="pb-4">
            <CCol :md="5" :sm="3" :xs="12">
               <h4>
                Unread Notifications: {{ notificationCount }}
              </h4>
            </CCol>
            <CCol :md="7" :sm="9" :xs="12">
                <CRow>
                  <CCol :md="9" :sm="6" :xs="12">
                  </CCol>
                  <CCol :md="3" :sm="6" :xs="12">
                    <button v-if="notificationCount > 0" type="button" class="btn btn-primary w-100" @click="markAllAsRead">Mark All As Read</button>
                  </CCol>
                </CRow>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      <th class="font-weight-bold">Description</th>
                      <th class="font-weight-bold">Time</th>
                      <th class="font-weight-bold">Reading Status</th>
                      <th class="font-weight-bold">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="notifications.length">
                    <tr
                      v-for="notification in notifications"
                      :key="notification.id"
                    >
                      <td>
                        <a
                  href="javascript:void(0)"
                  @click="viewNotificationInfo(notification)"
                  :class="notification.is_read ? '' : 'unread'"
                  >{{ notification.description }}</a
                >
                      </td>
                      <td>
                        <p>
                          <span class="r-time">{{
                            notification.created_at | relativeTime
                          }}</span>
                        </p>
                      </td>

                      <td>
                        <strong>{{ notification.is_read ? 'Read at '+ dateFormat(notification.read_at): 'Unread' }}</strong>
                      </td>

                      <td>
                        <button
                         @click="viewNotificationInfo(notification)"
                          type="button"
                          class="btn btn-primary btn-sm"
                          title="Display Raw Data"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="3">
                        <h4 class="text-center">Data not available.</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>

          <CPagination
            v-if="notifications.length"
            align="center"
            :pages="rows"
            :active-page.sync="filter.currentPage"
            @update:activePage="handlePageChange"
          />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Notification",
  data() {
    return {
      filter: {
        hardware_serial_no: "",
        type: "",
        status: "",
        currentPage: 1,
      },
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Notifications", path:"", isActive: true}]
    };
  },
  computed: {
    ...mapGetters("Notification", ["notifications", "rows", "perPage", "notificationCount"]),
  },
  methods: {
    getNotifications(filter) {
      filter.currentPage = 1;
      this.$store.dispatch("Notification/getNotifications", filter);
    },
    handlePageChange(value) {
      this.filter.currentPage = value;
      this.$store.dispatch("Notification/getNotifications", this.filter);
    },
    viewNotificationInfo(notification) {
      this.axios
        .put(`/admin/user-notifications/${notification.id}/mark-as-read`)
        .then(() => {
          this.$store.dispatch("Notification/updateNotificationCount", 'sub');
          if (notification.type === "meter_error") {
            this.$router.push({
              name: "Error Logs",
              params: { id: notification.notificationable.id },
            });
          } else if (notification.type === "water_vehicle_request") {
            this.$router.push({
              name: "Water Vehicle Detail",
              params: { id: notification.notificationable.id },
            });
          } else if (notification.type === "customer_support") {
            this.$router.push({
              name: "CustomerSupportView",
              params: { id: notification.notificationable.id },
            });
          } else if (notification.type === "task") {
            this.$router.push({
              name: "TaskView",
              params: { id: notification.notificationable.id },
            });
          } else {
            this.$router.push({
              name: "MeterView",
              params: { id: notification.notificationable.id },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    markAllAsRead() {
      this.$store.dispatch("Notification/markAllAsRead");
    },
    dateFormat(date) {
      return moment(date).format("Do MMM, YYYY h:mm a");
    },
  },
  mounted() {
    this.$store.dispatch("Notification/getNotifications", this.filter);
  },
  filters: {
    relativeTime: function(value) {
      if (!value) return "";
      return moment(value).fromNow();
    },
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
.badge {
  padding: 5px 7px;
}
.update-status-btn-width {
  width: 100%;
}
.hide-footer >>> footer {
  display: none;
}
</style>

<style scoped>
.custom-button {
  color: #000;
  padding: 10px 8px 10px 15px;
  font-size: 15px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
  line-height: 1;
}
.cdropdown {
  display: inline-block;
}

.card {
    border: none;
    padding: 15px 15px 0;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}
</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
      }
    }
  }
}

.unread {
  color: #000;
  font-weight: 600;
}

.r-time {
  display: block;
  font-size: 11px;
  color: #9e9e9e;
  margin-top: 6px;
}
a {
  color: #000;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none;
}
</style>
